import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { Id, Nil } from '@model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class OrganizationWebsocketService {
  private hubConnections: { [organizationId: string]: HubConnection | Nil } =
    {};
  public webSocketReconnectionSubject$ = new Subject<{
    organizationId: Id;
  }>();
  public webSocketStartSubject$ = new Subject<{ organizationId: Id }>();

  public initialize(organizationId: Id, url: string, token: string): void {
    if (!this.hubConnections[organizationId]) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => {
            return token;
          },
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnections[organizationId] = hubConnection;

      hubConnection.start().then(() => {
        this.webSocketStartSubject$.next({ organizationId });
      });

      hubConnection.onreconnected(() => {
        this.webSocketReconnectionSubject$.next({ organizationId });
      });
    }
  }

  public destroy(organizationId: Id): void {
    this.hubConnections[organizationId]?.stop();
    delete this.hubConnections[organizationId];
  }

  public on<Response>(
    organizationId: Id,
    methodName: string,
  ): Observable<Response> {
    return new Observable((observer) => {
      this.hubConnections[organizationId]?.on(methodName, (message) => {
        observer.next(message);
      });
    });
  }
}
